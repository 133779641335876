<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* html,body{
  height:100%;
  overflow:hidden;
} */

a {
  text-decoration: none;
}
/* html {
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
 
-webkit-filter: grayscale(100%);
} */
</style>
