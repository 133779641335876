import Vue from 'vue'
import Router from 'vue-router'
import { getToken } from "@/utils/auth"
Vue.use(Router)
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
 const constantRouter = [
    // {
    //     path: '/',
    //     name:'home',
    //     component:()=>import('@/views/home/home'),
    //     meta:{}
    // },
     {
         path: '/',
         name:'home',
         component:()=>import('@/views/secendHome/sencendIndex'),
         meta:{}
     },
    { 
        path: '/login',
        name:'login',
        component:()=>import('@/views/login/login'),
        meta:{}
    },
    { 
        path: '/upload',
        name:'upload',
        component:()=>import('@/views/upload/upload'),
        meta:{}
    },
    { 
        path: '/Layout',
        name:'Layout',
        component:()=>import('@/views/Layout/Layout'),
        // meta:{},
        redirect: '/Layout/cardList',
        children: [
            {
                path: 'cardList',
                component:()=>import('@/views/cardList/cardList'),
            },
            {
                path: 'signList',
                component:()=>import('@/views/signList/signList')
            },
            {
                path: 'experList',
                component:()=>import('@/views/expertList/experList'),
            },
            { 
                path: 'scoreList',
                component:()=>import('@/views/scoreList/scoreList'),
            },
            {
                path: 'myList',
                component: ()=>import('@/views/myList/myList'),
            },
            {
                path: 'marigerList',
                component: ()=>import('@/views/marigerList/marigerList'),
            },
            { 
                path: 'signList',
                component:()=>import('@/views/signList/signList'),
            },
        ]
    },
    
    
    
    // { 
    //     path: '/experList',
    //     name:'experList',
    //     component:()=>import('@/views/expertList/experList'),
    //     meta:{}
    // },
    // { 
    //     path: '/scoreList',
    //     name:'scoreList',
    //     component:()=>import('@/views/scoreList/scoreList'),
    //     meta:{}
    // },
    // {
    //     path: '/myList',
    //     name: 'myList',
    //     component: ()=>import('@/views/myList/myList'),
    //     meta:{}
    // }
]
const createRouter = () => {
    return  new Router({
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRouter,
        mode:'history'
        })
    }
const router = createRouter()
router.beforeEach((to,from,next)=>{
    nprogress.start()
    // if(getToken()){
    //     if(to.path == '/'){
    //         next('/');
    //     }else{
    //         next();
    //     }
    // }else{
    //     if(to.path =='/login'){
    //         next('/login');
    //     }else{
    //         next();
    //     }
    // }
     if(getToken()){
        if(to.path == '/login'){
            next('/');
        }else{
            next();
        }
    }else{
        if(to.path =='/login' || to.path =='/'){
            next();
        }else{
            next(false);
        }
        
    }
    
})
router.afterEach((to,from,next)=>{
    nprogress.done()
})
export default router